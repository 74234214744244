import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import {
  propertyHomeServiceInstance,
  REQUEST_STATUS,
} from 'src/services/PropertyHome.service';

const initialState = {
  isLoading: true,
  isCategoryStatusLoading: false,
  error: false,
  certification: {},
  certificationCategoryStatuses: [],
  updated: false,
  certificationLogsList: [],
  certificationLogs: [],
  categoryList: [],
  statusNotesList: [],
  statusNotesRecentList: [],
  selectedCertificationPageTab: '',
  infoToReject: null,
  rejectLoading: true,
};

const slice = createSlice({
  name: 'Certification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    setCategoryStatusLoadingState(state, action) {
      state.isCategoryStatusLoading = action.payload;
    },
    startLoadingNotes(state) {
      state.isLoadingNotes = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single certification
    getCertificationSuccess(state, action) {
      state.isLoading = false;
      state.certification = action.payload;
      state.updated = false;
    },

    // GET certification category statuses
    getCertificationCategoryStatusesSuccess(state, action) {
      state.certificationCategoryStatuses = action.payload;
    },

    // POST certification status
    updateCertificationStatusSuccess(state) {
      state.isLoading = false;
      state.updated = true;
    },

    setUpdatedSuccess(state, action) {
      state.updated = action.payload;
    },

    // GET Certification Logs
    getCertificationLogsSuccess(state, action) {
      state.isLoading = false;
      state.certificationLogsList = action.payload;
    },

    // GET Certification Logs per page
    appendCertificationLogsSuccess(state, action) {
      state.isLoading = false;
      state.certificationLogs = action.payload;
    },

    // GET Certifications Logs for the full page view
    getCertificationLogsSearchSuccess(state, action) {
      state.isLoading = false;
      state.certificationLogs = action.payload.results || action.payload;
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.categoryList = action.payload;
    },

    getStatusNotesListSuccess(state, action) {
      state.isLoadingNotes = false;
      state.statusNotesList = action.payload;
    },

    getStatusNotesRecentListSuccess(state, action) {
      state.isLoading = false;
      state.statusNotesRecentList = action.payload;
    },

    resetCertificationData(state) {
      state.isLoading = false;
      state.isLoadingNotes = false;
      state.error = false;
      state.certification = {};
      state.certificationCategoryStatuses = [];
      state.certificationLogsList = [];
      state.certificationLogs = [];
      state.categoryList = [];
      state.statusNotesList = [];
      state.statusNotesRecentList = [];
    },

    setSelectedCertificationPageTab(state, action) {
      const { tab } = action.payload;
      state.selectedCertificationPageTab = tab;
    },

    setInfoToReject(state, action) {
      state.infoToReject = action.payload;
    },

    setRejectLoading(state, action) {
      state.rejectLoading = action.payload;
    },

    updateCurrentCertification(state, action) {
      state.certification = { ...state.certification, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  resetCertificationData,
  setSelectedCertificationPageTab,
  setInfoToReject,
  setRejectLoading,
  updateCurrentCertification,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCertification(certificationId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await propertyHomeServiceInstance.getCertificationDetails(
      certificationId,
      queryParams
    );
    if (response.requestStatus === REQUEST_STATUS.PASSED) {
      response.data.survey_file_order_preference =
        response.data.certification_config?.survey_file_order_preference;
      response.data.certification_config =
        response.data.certification_config.id;
      dispatch(slice.actions.getCertificationSuccess(response.data));
    } else {
      dispatch(slice.actions.hasError(response.error));
    }
  };
}

export function updateCertificationProperties(certificationId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.setCategoryStatusLoadingState(true));
    const response = await propertyHomeServiceInstance.getCertificationDetails(
      certificationId,
      queryParams
    );
    if (response.requestStatus === REQUEST_STATUS.PASSED) {
      dispatch(slice.actions.updateCurrentCertification(response.data));
    } else {
      dispatch(slice.actions.hasError(response.error));
    }
    dispatch(slice.actions.setCategoryStatusLoadingState(false));
  };
}

export function getCategoryList(queryParams) {
  return async (dispatch) => {
    let response;
    dispatch(slice.actions.startLoading());
    try {
      response = await axios.get(
        `certification/category/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getCategoryListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return response;
  };
}

export function getCertificationCategoryStatuses(categorId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.setCategoryStatusLoadingState(true));
    try {
      const response = await axios.get(
        `certification/status/?category=${categorId}&${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(
        slice.actions.getCertificationCategoryStatusesSuccess(response.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setCategoryStatusLoadingState(false));
    }
  };
}

export function setUpdated(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setUpdatedSuccess(value));
  };
}

export function updateCertificationCategoryStatus(certificationId, statusId) {
  return async (dispatch) => {
    let requestStatus = REQUEST_STATUS.WAITING;
    dispatch(slice.actions.setCategoryStatusLoadingState(true));
    try {
      const data = {
        certification: certificationId,
        status: statusId,
      };

      await axios.post(`certification/status_step/`, data);
      requestStatus = REQUEST_STATUS.PASSED;
    } catch (error) {
      requestStatus = REQUEST_STATUS.FAILED;
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setCategoryStatusLoadingState(false));
    }
    return requestStatus;
  };
}

export function getCertificationLogs(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `compliance_log/compliance_log/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getCertificationLogsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function appendCertificationLogs(queryParams, currentLogs) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `compliance_log/compliance_log/?${parseQueryParams(queryParams)}`
      );
      const conversationsUpdated = currentLogs.concat(response.data.results);
      dispatch(
        slice.actions.appendCertificationLogsSuccess(conversationsUpdated)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCertificationLogsSearch(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `compliance_log/compliance_log/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getCertificationLogsSearchSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearCertificationLogs() {
  return async (dispatch) => {
    dispatch(slice.actions.getCertificationLogsSuccess([]));
  };
}

export function getStatusNotesList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingNotes());
    try {
      const response = await axios.get(
        `status_notes/status_note/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getStatusNotesListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStatusNotesRecentList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `status_notes/status_note/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getStatusNotesRecentListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
